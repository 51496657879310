import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';

@Component({
  selector: 'cs-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  galleryOptions: NgxGalleryOptions[];
  galleryOptionsLogo: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  galleryImagesLogo: NgxGalleryImage[];
  selectedImage: any;
  selectedImageLogo: any;

  @Input() modalData: any;
  @Input() modalType: any;

  public form: FormGroup;
  public dataToBeSentWhenModalCloses: any;
  public selected: number;
  public slideConfig = { 'slidesToShow': 3, 'slidesToScroll': 3, dots: true, arrows: false };

  constructor(
    private _formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any) { }

  ngOnInit() {

    this.galleryOptions = [
      { "thumbnailActions": [{ icon: 'fa fa-plus', onClick: this.handleClick.bind(this), titleText: '' }], "preview": false, "image": false, "height": "100px", "startIndex": 0, "thumbnailsColumns": 5 },
      { "breakpoint": 800, "width": "80%" }
    ]

    this.galleryOptionsLogo = [
      { "thumbnailActions": [{ icon: 'fa fa-plus', onClick: this.handleClickLogo.bind(this), titleText: '' }], "preview": false, "image": false, "height": "100px", "startIndex": 0, "thumbnailsColumns": 5 },
      { "breakpoint": 800, "width": "80%" }
    ]


    if (this.data.type === 'stepper') {
      this.galleryImages = this.data.images;
      this.galleryImagesLogo = this.data.imagesLogo;
      this.createPlacementFormFor(this.data.frame);
      if (this.form.controls['vehicleimage'].value !== "") {
        this.selectedImage = this.form.controls['vehicleimage'].value;
        if (this.selectedImage.indexOf("?") !== -1) this.selectedImage = this.selectedImage.substr(0, this.selectedImage.indexOf("?"))
        this.galleryImages.forEach((element, idx) => {
          if (element.small == this.selectedImage) {
            this.galleryOptions[0].startIndex = idx;
          }
        });
      } else {
        if (this.galleryImages) {
          this.selectedImage = this.galleryImages[0].small;
          this.form.controls['vehicleimage'].setValue(this.selectedImage);
        }
      }
      if (this.form.controls['logo'].value !== "") {
        this.selectedImageLogo = this.form.controls['logo'].value;
        if (this.selectedImageLogo.indexOf("?") !== -1) this.selectedImageLogo = this.selectedImageLogo.substr(0, this.selectedImageLogo.indexOf("?"))
        this.galleryImagesLogo.forEach((element, idx) => {
          if (element.small == this.selectedImageLogo) {
            this.galleryOptionsLogo[0].startIndex = idx;
          }
        });
      } else {
        this.selectedImageLogo = this.galleryImagesLogo[0].small;
        this.form.controls['logo'].setValue(this.selectedImageLogo);
      }
    }
  }

  public handleClick(event, index): void {
    console.log("event", event);
    //  const item = this.galleryImages.splice(index, 1);
    console.log("item", index);
    this.selectedImage = this.galleryImages[index].small;
    this.form.controls['vehicleimage'].setValue(this.selectedImage);
    const parentClass = document.getElementsByClassName("vehicle")[0];

    const element: any = parentClass.getElementsByClassName('ngx-gallery-thumbnail') as HTMLCollectionOf<Element>;
    element[index].click()
  }

  public handleClickLogo(event, index): void {
    console.log("event", event);
    //  const item = this.galleryImages.splice(index, 1);
    console.log("item", index);
    this.selectedImageLogo = this.galleryImagesLogo[index].small;
    this.form.controls['logo'].setValue(this.selectedImageLogo);
    const parentClass = document.getElementsByClassName("logo")[0];

    const element: any = parentClass.getElementsByClassName('ngx-gallery-thumbnail') as HTMLCollectionOf<Element>;
    element[index].click()
  }

  public createPlacementFormFor(clickedFrame?: any): void {
    switch (clickedFrame) {
      case 1:
        this.form = this._formBuilder.group({
          frame: [this.data.frame],
          backgroundimage1: [this.data.placementData.backgroundimage1 || '', Validators.required],
          backgroundimage2: [this.data.placementData.backgroundimage2 || '', Validators.required],
          logo: [this.data.placementData.logo1 || '', Validators.required],
          offerheadline: [this.data.placementData.offerheadline1 || '', Validators.required],
          vehicleimage: [this.data.placementData.vehicleimage1 || '', Validators.required],
          vehiclename: [this.data.placementData.vehiclename1 || '', Validators.required],
          ctalabel: [this.data.placementData.ctalabel1 || '', Validators.required],
          ctaurl: [this.data.placementData.ctaurl1 || '', Validators.required],
          disclosure: [this.data.placementData.disclosurecopy1 || '', Validators.required]
        });
        break;
      case 2:
        this.form = this._formBuilder.group({
          frame: [this.data.frame],
          backgroundimage1: [this.data.placementData.backgroundimage1 || '', Validators.required],
          backgroundimage2: [this.data.placementData.backgroundimage2 || '', Validators.required],
          logo: [this.data.placementData.logo2 || '', Validators.required],
          offerheadline: [this.data.placementData.offerheadline2 || '', Validators.required],
          vehicleimage: [this.data.placementData.vehicleimage2 || '', Validators.required],
          vehiclename: [this.data.placementData.vehiclename2 || '', Validators.required],
          ctalabel: [this.data.placementData.ctalabel2 || '', Validators.required],
          ctaurl: [this.data.placementData.ctaurl2 || '', Validators.required],
          disclosure: [this.data.placementData.disclosurecopy2 || '', Validators.required]
        });
        break;
      case 3:
        this.form = this._formBuilder.group({
          frame: [this.data.frame],
          backgroundimage1: [this.data.placementData.backgroundimage1 || '', Validators.required],
          backgroundimage2: [this.data.placementData.backgroundimage2 || '', Validators.required],
          logo: [this.data.placementData.logo3 || '', Validators.required],
          offerheadline: [this.data.placementData.offerheadline3 || '', Validators.required],
          vehicleimage: [this.data.placementData.vehicleimage3 || '', Validators.required],
          vehiclename: [this.data.placementData.vehiclename3 || '', Validators.required],
          ctalabel: [this.data.placementData.ctalabel3 || '', Validators.required],
          ctaurl: [this.data.placementData.ctaurl3 || '', Validators.required],
          disclosure: [this.data.placementData.disclosurecopy3 || '', Validators.required]
        });
        break;
      default:
        break;
    }
  }

  public onFrameTemplateSelect(e: any): void {
    //console.log('this was selected', e);
  }

  public clickOffer(i: any, campaignName: HTMLElement, campaignDetails: HTMLElement): void {
    this.dataToBeSentWhenModalCloses = {
      'campaignData': this.data.selectOfferData[i],
      'campaignName': campaignName.textContent,
      'campaignDetails': campaignDetails.textContent
    };
  }

  public updateSelected(i: any): void {
    //  console.log('i was changed', i);
    this.selected = i;
  }

  public onDone(): void {
    // console.log('done', this.dataToBeSentWhenModalCloses);
    this.dialogRef.close(this.dataToBeSentWhenModalCloses);
  }

  public onClose(): void {
    this.dialogRef.close();
  }


  public onComplete(): void {
    this.dialogRef.close(this.form.value);
    //    console.log('The Form complete', this.form.value);
  }

  save() {
    this.dialogRef.close(true);
  }

  close() {
    this.dialogRef.close();
  }

}
