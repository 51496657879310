// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  clientAppDomain: 'http://dev.fdcampaignstudio.com/',
  apiResetPwdUrl: 'https://devapi.fdcampaignstudio.com/dco/api/resetpassword',
  images_url_head: 'https://creativesham.blob.core.windows.net/progad/',
  apiDealerUrl: 'https://www.fdcampaignstudio.com/api/udldelta/dealers/getdata',
  apiAdvDealerUrl: 'https://devapi.fdcampaignstudio.com/dco/advDealers',
  apiAdvDealerFLsUrl: 'https://qaapi.fdcampaignstudio.com/dco/advDealers/uploadFloodlights',
  apiDealersUrl: 'https://devapi.fdcampaignstudio.com/dco/dealers',
  apiUserUrl: 'https://devapi.fdcampaignstudio.com/dco/dcousers/login',
  apiPostDealerUrl: 'https://devapi.fdcampaignstudio.com/dco/dealers',
  apiPostUserUrl:
    'https://devapi.fdcampaignstudio.com/dco/dcousers',
  apiPatchUserUrl:
    'https://devapi.fdcampaignstudio.com/dco/dcousers',
  apiAllUsers: 'https://devapi.fdcampaignstudio.com/dco/dcousers',
  apiAllDealers: 'https://devapi.fdcampaignstudio.com/dco/advDealers',
  apiPlacementsUrl: 'https://devapi.fdcampaignstudio.com/dco/placements',
  apiPostOemOffers: 'https://devapi.fdcampaignstudio.com/dco/oemoffers',
  apiPostDcoUrl: 'https://devapi.fdcampaignstudio.com/dco/campaigns',
  apiNewDcoUrl: 'https://devapi.fdcampaignstudio.com/dco/ads?pacode=',
  apiPostNewDcoUrl: 'https://devapi.fdcampaignstudio.com/dco/ads',
  apiDcoUrl: 'https://devapi.fdcampaignstudio.com/dco/campaigns?pacode=',
  offerInfo:
    'http://www.servicesus.ford.com/incentives/SpecialOffers.json?appContext=T2&',
  uploadAzureZip:
    'https://creativesham.blob.core.windows.net/html5templates/',
  downloadAzureZip:
    'https://creativesham.blob.core.windows.net/html5templates/',
  uploadImagesUrl:
    'https://creativesham.blob.core.windows.net/imagetest/',
  getImagesUrl:
    'https://creativesham.blob.core.windows.net/test?restype=container&comp=list',
  apiPostSelectedOfferUrl: 'https://devapi.fdcampaignstudio.com/dco/adapprovals',
  emailLinkUrl:
    'https://dev.fdcampaignstudio.com/campaign_approval?randomcode=',
  offerApprovalApi: 'https://devapi.fdcampaignstudio.com/dco/offerapprovals',
  offerApprovalEmailLink:
    'http://dev.fdcampaignstudio.com/offer-approval/dealer',
  offer_email_subject: 'Action Required: Please select offers',
  offer_email_body:
    'Hello <dealer-business-name>,  \n\nNew offers have just been announced on buyfordnow.com. Please click on the link to review and select the offers you would like to highlight this month. \n\n<link>\n\nThank you\n<userFirstName>',
  offer_email_from: 'admin@fdcampaignstudio.com',
  apigetAdgroup: 'https://devapi.fdcampaignstudio.com/google-ads/getAdGroups',
  apigetAccountNumber:
    'https://devapi.fdcampaignstudio.com/google-ads/getAccountName',
  submitToGoogleUrl:
    'https://devapi.fdcampaignstudio.com/google-ads/createSearchAd',
  apiCreateLabel: 'https://devapi.fdcampaignstudio.com/google-ads/createLabel',
  nodeUrl: 'http://127.0.0.1:3000',
  storageUri: 'https://creativesham.blob.core.windows.net/',
  storageContainer: 'test',
  udlApiStatusUrl: 'https://devapi.fdcampaignstudio.com/udldelta/status/health',
  dcoApiStatusUrl: 'https://devapi.fdcampaignstudio.com/dco/status/health',
  appVersion: '1.1-DEV'
};

/*
ng build

 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
