import { NgModule } from '@angular/core';
import {
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatGridListModule,
  MatDialogModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatStepperModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatToolbarModule,
  MatButtonToggleModule,
  MatExpansionModule,
  MatTabsModule,
  MatDatepickerModule,
  MatTooltipModule, MatProgressSpinnerModule, MatPaginatorModule,
  MatRadioModule, MatBadgeModule
} from '@angular/material';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import { TooltipModule } from 'ngx-bootstrap';
import { SafeHtmlPipe } from './safehtml.pipe';
import { UtcDatePipe } from './utc-date.pipe';


@NgModule({
  imports: [
    MatButtonModule,
    MatBadgeModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSnackBarModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatTabsModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    MatToolbarModule,
    MatButtonToggleModule,
    MatRadioModule,
    TooltipModule.forRoot(),
    SlickCarouselModule
  ],
  declarations: [SafeHtmlPipe, UtcDatePipe],
  exports: [
    MatButtonModule,
    MatBadgeModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    SlickCarouselModule,
    MatSnackBarModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatTabsModule,
    MatTableModule,
    MatRadioModule,
    MatTooltipModule,
    MatToolbarModule,
    MatButtonToggleModule,
    SafeHtmlPipe,
    UtcDatePipe
  ]
})
export class MaterialModule {}
