﻿import { Timestamp } from "rxjs/internal/operators/timestamp";

export class Ad {
    userid: String;
    make: String;
    model: String;
    year: String;
    template:String;
    dealerid: String;
    dealername: String;
    dealerurl: String;
    pacode: String;
    postalcode: String;
    offer :  Array<Offers>;
    created_date: Date;
    updated_date: String;
    publisher: String;
    active:Boolean;
    mcmid:String;
}

export class Offers {
  size: String;
  placement: Array<Placements>;
  version:Number;
  offerheadline1: String;
  vehiclename1: String;
  ctalabel1: String;
  ctaurl1: String;
  disclosurelabel1: String;
  logo1: String;
  vehicleimage1: String;
  offerheadline2: String;
  vehiclename2: String;
  ctalabel2: String;
  ctaurl2: String;
  disclosurelabel2: String;
  logo2: String;
  vehicleimage2: String;
  offerheadline3: String;
  vehiclename3: String;
  ctalabel3: String;
  ctaurl3: String;
  disclosurelabel3: String;
  logo3: String;
  vehicleimage3: String;
  disclosurecopy1: String;
  disclosurecopy2: String;
  disclosurecopy3: String;
  backgroundimage1: String;
  backgroundimage2: String;
  backgroundurl: String;
  active: Boolean;
  adoffer: Array<AdOffers>;
  offer_type: String;
  offer_start_date:Date;
  offer_end_date:Date;
  export_date:Date;
}

export class Placements {
    placementid: String;
    name: String
}

export class AdOffers {
  sent_to_dealer: Boolean;
  sent_by: String;
  sent_on: Date;
  dealer_response: String;
  comment: String;
  response_on: Date;
  is_valid: Boolean
}


