﻿import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

import { SessionService } from './session.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private sessionService: SessionService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = JSON.parse(this.sessionService.currentUserValue);
    if (currentUser) {
      // check if route is restricted by role
      // if (route.data.roles && route.data.roles.indexOf(currentUser[0].role) === -1) {
      // role not authorised so redirect to home page
      //    this.router.navigate(['/']);
      //    return false;
      // }

      // authorised so return true
      const currentUserRole = currentUser[0].role;
      console.log(currentUserRole);
      if (currentUserRole == 'DceDashboard') {
        if (state.url !== '/dcedashboard') {
          this.router.navigate(['/login'], {
            queryParams: { returnUrl: state.url }
          });
          return false;
        } else {
          return true;
        }
      }
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
