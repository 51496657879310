import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class CampaignService {
  constructor(private http: HttpClient) {}

  public getDealerPlacements(): Observable<any> {
    const url = '';
    return this.http.get(url)
      .pipe(map((response) => response));
  }
}
