import { Injectable } from '@angular/core';
import { UtilityService } from '../utility/utility.service';
import { Location } from '@angular/common';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ActivatedRoute
} from '@angular/router';

import { SessionService } from './session.service';

@Injectable({ providedIn: 'root' })
export class PermissionGuard implements CanActivate {
  constructor(
    private router: Router,
    private sessionService: SessionService,
    private utilityService: UtilityService,
    private activatedroute: ActivatedRoute
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = JSON.parse(this.sessionService.currentUserValue);
    if (currentUser) {
      const applications = currentUser[0].applications;
      const currentUserRole = currentUser[0].role;

      if (currentUserRole === 'User') {
        //permission logic
        if (state.url === '/ads') {
          const displaymenu = applications.filter(function(obj) {
            return obj.name === 'Create Display Ad';
          });

          if (Object.keys(displaymenu).length === 0) {
            this.utilityService.startLoadingSpinner();
            localStorage.clear();
            this.router.navigateByUrl('login').then(() => {
              this.utilityService.stopLoadingSpinner();
            });
          } else {
            for (let i = 0; i < displaymenu.length; i++) {
              if (
                displaymenu[i].name === 'Create Display Ad' &&
                displaymenu[i].active === true
              ) {
                return true;
              } else {
                this.utilityService.startLoadingSpinner();
                localStorage.clear();
                this.router.navigateByUrl('login').then(() => {
                  this.utilityService.stopLoadingSpinner();
                });
              }
            }
          }
        } else if (state.url === '/search-ads') {
          const search = applications.filter(function(obj) {
            return (
              obj.name === 'Create Search Ad' ||
              obj.name === 'View All Search Ads'
            );
          });
          if (Object.keys(search).length === 0) {
            this.utilityService.startLoadingSpinner();
            localStorage.clear();
            this.router.navigateByUrl('login').then(() => {
              this.utilityService.stopLoadingSpinner();
            });
          } else {
            for (let i = 0; i < search.length; i++) {
              if (
                search[i].name === 'Create Search Ad' &&
                search[i].active === true
              ) {
                return true;
              } else if (
                search[i].name === 'View All Search Ads' &&
                search[i].active === true
              ) {
                return true;
              } else {
                this.utilityService.startLoadingSpinner();
                localStorage.clear();
                this.router.navigateByUrl('login').then(() => {
                  this.utilityService.stopLoadingSpinner();
                });
              }
            }
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      this.utilityService.startLoadingSpinner();
      localStorage.clear();
      this.router.navigateByUrl('login').then(() => {
        this.utilityService.stopLoadingSpinner();
      });
    }
  }
}
