﻿import { Role } from './role';
import { Timestamp } from 'rxjs/internal/operators/timestamp';

export class User {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  dealers: Array<Dealers>;
  public applications: Array<Applications>;
  public role: Role;
  token: string;
  added_on:Date; 
  updated_on: Date;
  active: boolean;
}

export class Applications {
  name: string;
  key: string;
  active: boolean;
}

export class NewDealer {
  pacode: string;
  logo_url: string;
  brand: string;
  friendly_name: string;
  friendly_url: string;
  account_number: string;
  region: string;
  onboarded_date: string;
  onboarded_by: string;
  deactivated_by: null;
  deactivated_date: string;
  active: boolean;
  template: string;
}

export class Dealers {
  pacode: string;
  brand: number;
  logo_url: string;
  friendly_url: string;
  onboarded_date: Date;
  onboarded_by: string;
  deactivated_date?: Date;
  deactivated_by?: string;
  active: boolean;
}

export interface Element {
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  role: string;
  show: boolean;
}
