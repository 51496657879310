import {NgModule} from '@angular/core';
import {AdsHeaderComponent} from './ads-header.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {MaterialModule} from '@cs/material';
import {UtilityService} from '@cs/core-data';
import {NgxSpinnerModule} from "ngx-spinner";
// import {AdsExportModule} from '../ads-export/ads-export.module';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgxSpinnerModule
    // AdsExportModule
  ],
  declarations: [AdsHeaderComponent],
  providers: [UtilityService],
  exports: [AdsHeaderComponent]
})
export class AdsHeaderModule {}
