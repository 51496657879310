import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from '../utility/utility.service';
import { Session } from './session';
import { LocalstorageService } from '../localstorage/localstorage.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  public session$: any = null;
  private sessionSubject: BehaviorSubject<
    Session.SESSION
  > = new BehaviorSubject<any>({});
  private userProfileSubject: BehaviorSubject<
    Session.USERPROFILE
  > = new BehaviorSubject<any>({});
  private currentUserSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    ''
  );
  private dealersSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});

  constructor(
    private http: HttpClient,
    private localStorage: LocalstorageService,
    private utilityService: UtilityService,
    private toastService: ToastrService
  ) {}

  get session(): any {
    return this.session$;
  }

  get user(): any {
    return {
      username: this.session.username,
      pacode: this.session.pacode,
      role: this.session.role,
      token: this.session.token,
      dealers: this.session.dealers
    };
  }

  get currentUserValue(): any {
    this.currentUserSubject = new BehaviorSubject<any>(
      sessionStorage.getItem('currentUser')
    );
    return this.currentUserSubject.value;
  }

  getAllUsers(environment): Observable<any> {
    const url = `${environment.apiAllUsers}`;

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('jwtToken')
      })
    };
    return this.http.get(url, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  public login(
    username: string,
    password: string,
    environmentUrl: any
  ): Observable<any> {
    const bodyParams = {
      dcoclientid: username,
      dcoclientpassword: password
    }

    return this.http
      .post(environmentUrl.apiUserUrl, bodyParams)
      .pipe(catchError(error => this.errorHandler(error)))
      .pipe(
        map(user => {
          if (user) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            user = _.map(user, o => _.extend({ username: username }, o));
            this.currentUserSubject.next(user);
            sessionStorage.setItem('currentUser', JSON.stringify(user));
          }
          return user;
        })
      );
  }

  public formatTimeStamp(date: Date): any {
    let hours: any = new Date().getHours();
    let minute: any = new Date().getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minute = minute < 10 ? '0' + minute : minute;
    const strTime = `${hours}:${minute} ${ampm}`;
    return (
      date.getMonth() +
      1 +
      '/' +
      date.getDate() +
      '/' +
      date.getFullYear() +
      ' ' +
      strTime
    );

    // const year = new Date().getFullYear();
    // const month = new Date().getMonth();
    // const date = new Date().getDate();
    // const hour = new Date().getHours();
    // const minute = new Date().getMinutes();
    // const second = new Date().getSeconds();
    // return new Date(year, month, date, hour, minute, second, 567)
  }

  public getSession(): Observable<Session.SESSION> {
    return this.sessionSubject.asObservable();
  }

  public getUser(userName, userPasswd, environment: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        dcoclientid: userName,
        dcoclientpassword: userPasswd
      })
    };

    return this.http
      .get(environment.apiUserUrl, httpOptions)
      .pipe(catchError(this.handleError));
  }

  public getDealers(): Observable<any> {
    return this.dealersSubject.asObservable();
  }

  public setDealers(dealers: Array<any>): void {
    this.dealersSubject.next(dealers);
  }

  // Get and Set User Profile
  public getUserProfile(): Observable<any> {
    return this.userProfileSubject.asObservable();
  }

  public setUserProfile(userProfile: any): void {
    this.userProfileSubject.next(userProfile);
  }

  private errorHandler(errorResponse: any): Observable<{}> {
    console.log('Error', errorResponse);
    this.toastService.error(`${errorResponse.error.message}`, 'test', {
      progressBar: true
    });
    return throwError(errorResponse);
  }

  private createRequestOptions(username: string, userpassword: string): any {
    let headers: HttpHeaders = new HttpHeaders(
        {
        dcoclientid: username,
        dcoclientpassword: userpassword
      }
    );
    headers = headers.append(
      'Accept',
      'text/html,application/json,application/xhtml+xml,application/xml;q=0.9,image/webp,*/*;q=0.8'
    );
    headers = headers.append('Access-Control-Allow-Origin', '*');
    return { headers: headers };
  }

  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  private extractData(res: Response) {
    const body = res;
    return body || {};
  }
}
