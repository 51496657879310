import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material';

import * as _ from 'lodash';
import * as CryptoJS from 'crypto-js';

import { FormBuilder, FormGroup, FormArray, Validators, FormControl} from '@angular/forms';

import * as moment from 'moment';

import { ToastrService } from 'ngx-toastr';
import { Angular2CsvComponent } from 'angular2-csv';
import {LocalstorageService, UtilityService} from '@cs/core-data';
import {Subject} from 'rxjs';
import { environment } from '../../../environments/environment';
import { ModalComponent } from '@cs/ui-modal';
import { CampaignService } from './campaign.service';


@Component({
  selector: 'cs-ads-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss']
})

export class CampaignComponent implements OnInit, OnDestroy {

  private dialog: MatDialog;
  private formBuilder: FormBuilder;

  constructor(
    private router: Router,
    private http: HttpClient,
    private dcoDealerService: CampaignService,
    private localStorage: LocalstorageService,
    private utilityService: UtilityService,
    private toastrService: ToastrService
  ) {}


  @ViewChild('csvComponent') csvComoponent: Angular2CsvComponent;

  @Input()
  public dialogid: number;
  public textvalue: string;
  public urlvalue: string;
  public rowHeight = 40;

  public itemsInView = [];
  public startIndex = 0;
  public endIndex = 0;
  public buttonText = 'Download CSV';
  public requestChange = false;


  public csvData = [];
  public newFileName: any;
  panelOpenState = false;

  public options: any = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    headers: ['Placement ID', 'Version', 'Size', 'PA Code', 'Offer Headline 1', 'Vehicle Name 1', 'CTA Label 1', 'CTA URL 1', 'Disclosure Label 1', 'Logo 1', 'Vehicle Image 1', 'Offer Headline 2', 'Vehicle Name 2', 'CTA Label 2', 'CTA URL 2', 'Disclosure Label 2', 'Logo 2', 'Vehicle Image 2', 'Offer Headline 3', 'Vehicle Name 3', 'CTA Label 3', 'CTA URL 3', 'Disclosure Label 3', 'Logo 3', 'Vehicle Image 3', 'Disclosure Copy 1', 'Disclosure Copy 2', 'Disclosure Copy 3', 'Background Image 1', 'Background Image 2', 'Background URL', 'Start Date', 'End Date'],
    showTitle: false,
    useBom: false,
    title: 'Placement IDs',
    removeNewLines: true,
    keys: ['placementid', 'version', 'size', 'pacode', 'offerheadline1', 'vehiclename1', 'ctalabel1', 'ctaurl1', 'disclosurelabel1', 'logo1', 'vehicleimage1', 'offerheadline2', 'vehiclename2', 'ctalabel2', 'ctaurl2', 'disclosurelabel2', 'logo2', 'vehicleimage2', 'offerheadline3', 'vehiclename3', 'ctalabel3', 'ctaurl3', 'disclosurelabel3', 'logo3', 'vehicleimage3', 'disclosurecopy1', 'disclosurecopy2', 'disclosurecopy3', 'backgroundimage1', 'backgroundimage2', 'backgroundurl', 'offer_start_date' , 'offer_end_date']
  };

  id = '';
  active: Boolean;
  date_created: Date;
  dealerid =  '';
  dealername = '';
  dealerurl = '';
  export_date: Date;
  offer_start_date: Date;
  offer_end_date: Date;
  make = '';
  model = '';
  pacode = '';
  placement: [{
    size: String,
    placementid: String,
    offerheadline1: String,
    vehiclename1: String,
    ctalabel1: String,
    ctaurl1: String,
    disclosurelabel1: String,
    logo1: String,
    vehicleimage1: String,
    offerheadline2: String,
    vehiclename2: String,
    ctalabel2: String,
    ctaurl2: String,
    disclosurelabel2: String,
    logo2: String,
    vehicleimage2: String,
    offerheadline3: String,
    vehiclename3: String,
    ctalabel3: String,
    ctaurl3: String,
    disclosurelabel3: String,
    logo3: String,
    vehicleimage3: String,
    disclosurecopy1: String,
    disclosurecopy2: String,
    disclosurecopy3: String,
    backgroundimage1: String,
    backgroundimage2: String,
    backgroundurl: String
    active: Boolean;
    version: Number;
  }];
  postalcode = '';
  publisher = '';
  updated_date: Date;
  userid = '';
  year = '';


  public dcoForm: FormGroup;
  public user: any;
  public valueSearch = '';
  public search: string = null;
  public dcos: any;
  public groupedDcos: any;
  public groupedDcoIds: any;
  public placementSize = '(300x600)';
  public approved = 'APPROVED';
  public decryptedUrl: any;

  private filteredDcos: any;
  private sortedDcos: any;
  private selectedDco: any;
  private selectedPlacementData: any;
  private groupedDcoKey: any;
  public selectedPlacement: any;
  private selectedSize: any;
  private selectedDcoID: any;
  private zipCode: any;
  private selectedDcoIndex = 0;
  private tempPlacement: any;
  private newPlacement: any;
  private isActive = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();


  ngOnInit() {
    this.user = this.localStorage.getStoredUser;
    this.utilityService.pacodeHasChanged
      .subscribe((newPacode) => {
        console.log('new Pacode', newPacode);
        if (newPacode) {
        this.getDealershipData(newPacode);
      }
    });

    /*
      comment out when useing local host
     */

    this.decryptUrl();

    this.getDealershipZip(this.user.pacode);

    this.csvData = [];

    const userPacodes: any = JSON.parse(localStorage.getItem('userDealers'));
    userPacodes.forEach(element => {
      if (element === this.user.pacode) {
        this.getDealershipData(element);
      } else {
        this.concatOtherPacodesInCSV(element);
      }
    });
  }

  public decryptUrl(): void {
    const decrypt = CryptoJS.AES.decrypt(this.router.url.split('randomcode=')[1], localStorage.getItem('passEcode'));
    const decryptedString = decrypt.toString(CryptoJS.enc.Utf8);
    console.log('url', this.router.url.split('randomcode=')[1]);
    console.log('decrypt is', decryptedString);
  }

  public onApprove(): void {
    this.toastrService.success(`Thank You, Placement ID: ${this.selectedPlacement} has been Approved`);
  }

  public onReject(): void {
    this.toastrService.error(`Rejected, Placement ID: ${this.selectedPlacement}, leave comment`);
  }

  public onRequestChange(): void {
    this.requestChange = !this.requestChange;
  }

  public getDealerPlacementForApproval(): void {
    this.dcoDealerService.getDealerPlacements()
      .subscribe((data) => {
        console.log('Data', data);
      });
  }

  // Search Functionality by Make,Model,Year and Placement Id
  searchCampaign(event: any) {
    const query = event.target.value;
    this.groupedDcoIds = Object.keys(this.groupedDcos);
    if (query) {
      this.groupedDcoIds = this.groupedDcoIds.filter((id) => {
        return (id.toLowerCase().indexOf(query.toLowerCase()) !== -1) || this.filterByPlacementId(id, query);
      });
    }
  }
  // Search by Placement Id
  filterByPlacementId(dco, query) {
    let isPlacementIDPresent = false;
    this.groupedDcos[dco].forEach(element => {
      if (element.placement[0].placementid.indexOf(query) !== -1) {
        isPlacementIDPresent = true;
      }
    });
    return isPlacementIDPresent;

  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private getDealershipZip(pacode: any): any {
    const postPayload = [
      {'propName': 'column', 'value': 'busname'},
      {'propName': 'column', 'value': 'lbusname'},
      {'propName': 'column', 'value': 'dlrpa'},
      {'propName': 'column', 'value': 'zip'},
      {'propName': 'column', 'value': 'url'}
    ];
    const url = `${environment.apiDealerUrl}/${pacode}`;
    this.utilityService.postDealer(pacode, environment)
      .subscribe((zipCodeData) => {
        return this.zipCode = zipCodeData[0].zip;
      });
  }

  getAlternateImg(originalUrl: any, dcoField) {
    let image_url = originalUrl;
    image_url = 'https://creativesham.blob.core.windows.net/progad/image-not-found.jpg?sv=2018-03-28&ss=b&srt=sco&sp=rwdlac&se=2028-12-20T02:47:06Z&st=2018-12-19T18:47:06Z&spr=https&sig=NhhGwkqwDaf5FLu%2FRJ09FUaGC8AYm4DyQvV6Q7uGjjQ%3D';
    this.dcoForm.controls['placement'].value[0][dcoField] = image_url;
  }

  public getDealershipData(pacode: any): any {
    this.utilityService.getDcos(pacode, environment)
      .subscribe((data) => {
        this.dcos = data;
        const valCsvData = JSON.stringify(this.dcos);
        this.saveDataforCSV(valCsvData);
        this.dcos.forEach((dco) => {
          dco.postalcode = this.zipCode;
        });
        this.setupForm(false, true, this.dcos);
      }, err => {
        console.log(err);
        this.showError();
        this.router.navigate(['login']);
      });
  }

  public concatOtherPacodesInCSV(pacode: any): any {
    this.utilityService.getDcos(pacode, environment)
      .subscribe((data) => {
        const valCsvData = JSON.stringify(data);
        this.saveDataforCSV(valCsvData);
      }, err => {
        console.log(err);
        this.showError();
        this.router.navigate(['login']);
      });
  }

  public onTogglePlacement(e: any, index: any): void {
    console.log('E was Triggered', e);
    console.log('index', index);
  }

  public onSelectedPlacementId(i: any): void {
    console.log(i);
    this.isActive = true;
  }


  public setupForm(loadFromPost?: boolean, withInitValues?: boolean, data?: any, i?: any): any {
    if (withInitValues) {
      const placements: FormArray = this.formBuilder.array([]);
      placements.push(this.formBuilder.group(data[0].placement[0]));

      if (data) {
        this.filteredDcos = _.filter(data, dco => dco.active === true);
        this.sortedDcos = _.sortBy(this.filteredDcos, dco => dco.updated_date).reverse();
        this.groupedDcos = _.groupBy(this.sortedDcos, (value) => {
          return `${value.make}${value.model}${value.year}${value.pacode}`;
        });

        this.groupedDcoIds = Object.keys(this.groupedDcos);

        this.dcoForm = this.formBuilder.group({
          _id : new FormControl(data[0]._id, Validators.required),
          active: new FormControl(data[0].active),
          created_date: new FormControl(data[0].created_date),
          dealerid: new FormControl(data[0].dealerid),
          dealername: new FormControl(data[0].dealername),
          dealerurl: new FormControl(data[0].dealerurl),
          export_date: new FormControl(data[0].export_date),
          offer_start_date: new FormControl(data[0].offer_start_date),
          offer_end_date: new FormControl(data[0].offer_end_date),
          make: new FormControl(data[0].make, Validators.required),
          model: new FormControl(data[0].model, Validators.required),
          pacode: new FormControl(data[0].pacode),
          placement: placements,
          postalcode: new FormControl(this.zipCode),
          publisher: new FormControl(data[0].publisher),
          updated_date: new FormControl(data[0].updated_date),
          userid: new FormControl(data[0].userid),
          year: new FormControl(data[0].year, Validators.required)
        });
        // Do not remove the below line this is for html5 data tables - to move the selected placement id to the top of the list //
        localStorage.setItem('clickPlacementId', null);
        localStorage.setItem('clickPlacementSize', null);
      }
    } else {
      this.dcoForm.reset();
      const placements: FormArray = this.formBuilder.array([]);
      placements.push(this.formBuilder.group(this.groupedDcos[data][i].placement[0]));
      this.dcoForm.setControl('placement', placements);

      this.dcoForm.patchValue({
        _id : this.groupedDcos[data][i]._id,
        active: this.groupedDcos[data][i].active,
        created_date: this.groupedDcos[data][i].created_date,
        dealerid: this.groupedDcos[data][i].dealerid,
        dealername: this.groupedDcos[data][i].dealername,
        dealerurl: this.groupedDcos[data][i].dealerurl,
        export_date: this.groupedDcos[data][i].export_date,
        offer_start_date: this.groupedDcos[data][i].offer_start_date,
        offer_end_date: this.groupedDcos[data][i].offer_end_date,
        make: this.groupedDcos[data][i].make,
        model: this.groupedDcos[data][i].model,
        pacode: this.groupedDcos[data][i].pacode,
        placement: this.groupedDcos[data][i].placement,
        postalcode: this.zipCode,
        publisher: this.groupedDcos[data][i].publisher,
        updated_date: this.groupedDcos[data][i].updated_date,
        userid: this.groupedDcos[data][i].userid,
        year: this.groupedDcos[data][i].year
      });
      console.log('Dco Form Patch NEW', this.dcoForm);
    }
  }

  selectDco(key, i) {
    this.selectedDco = key;
    this.selectedDcoIndex = i;

    // Activate placement id
    this.selectedPlacement = this.groupedDcos[this.groupedDcoIds[i]][0].placement[0].placementid;
    const placementSize = this.groupedDcos[this.groupedDcoIds[i]][0].placement[0].size;

    // Update form to render data for selected DCO
    this.selectPlacement(this.groupedDcos[key], 0, this.selectedPlacement, placementSize, key);
    this.utilityService.onSelectedDcoPassData.next(this.selectedDco);
  }

  selectPlacement(data, p, i, psize, key?) {
    // console.log('Grouped from placement', this.groupedDcos);
    // console.log('this Grouped Name key', key);
    // console.log('Select Placement Data', data);
    // console.log('Dco Form', this.dcoForm);
    // console.log('Selected Placement', i);
    // console.log('Seleced Dco Id', p);
    this.selectedPlacementData = data;
    this.groupedDcoKey = key;
    this.selectedDcoID = p;
    this.selectedPlacement = i;
    this.selectedSize = psize;
    // Do not remove the below line this is for html5 data tables - to move the selected placement id to the top of the list //
    localStorage.setItem('clickPlacementId', i);
    localStorage.setItem('clickPlacementSize', psize);
    this.newFileName = `${this.selectedPlacement}-${this.selectedSize}`;
    this.setupForm(false, false, key, p);
  }


  public onAddOffer(): void {
    // const replaceUrl: any = this.router.url.replace(new RegExp('/[^/]*$'), '/');
    // const newURl: any = replaceUrl + 'dco-create';
    // console.log('url', this.router.url);
    // console.log(newURl);
    this.router.navigateByUrl('dcos/create');
  }

  public onExport(): void {
    this.router.navigateByUrl('dcos/export');
  }



  public onTabPlacementChange(e: any): void {
    if (e.index === 0) {
      this.placementSize = '(300x600)';
    } else if (e.index === 1) {
      this.placementSize = '(300x250)';
    } else if (e.index === 2) {
      this.placementSize = '(728x90)';
    } else {
      this.placementSize = '';
    }
  }

  onFormUpdate() {
    if (this.dcoForm.pristine === true) {
      this.openPopup('update', this.tempPlacement, true);
    } else {
      // No changes so do nothing
      this.openPopup('no change', null, false);
    }
  }

  public onFormSave(): void {
    this.openPopup('save', this.selectedPlacementData, true);
  }


  public deleteDco(): void {
    this.openPopup('delete', this.selectedPlacementData, false);
  }

  saveDataforCSV(myGroupedData) {
    const tmpcsvData = JSON.parse(myGroupedData);
    tmpcsvData.forEach(pitem => {
      this.csvData.push(pitem.placement[0]);
      this.csvData[this.csvData.length - 1].placementid =  this.csvData[this.csvData.length - 1].placementid.substr(0, this.csvData[this.csvData.length - 1].placementid.indexOf( '-' ));
      this.csvData[this.csvData.length - 1].pacode = pitem.pacode;
      this.csvData[this.csvData.length - 1].offer_start_date = moment(pitem.offer_start_date).format('MM/DD/YYYY');
      this.csvData[this.csvData.length - 1].offer_end_date = moment(pitem.offer_end_date).format('MM/DD/YYYY');
    });
  }



  public openDialogForFrame(frame: any): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '1270px';
    dialogConfig.height = '750px';
    dialogConfig.data = {
      'type': 'stepper',
      'applicationType': 'dco',
      'frame': frame,
      'placementData': this.groupedDcos[this.groupedDcoKey][this.selectedDcoID].placement[0],
      'groupedKey': this.groupedDcoKey,
      'selectedDcoID': this.selectedDcoID
    };

    const dialogRef = this.dialog.open(ModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(val => {
      if (val.frame === 1) {
        if (val.backgroundimage1 !== '') {
          // const placements: FormArray = <FormArray>this.dcoForm.controls['placement'];
          this.groupedDcos[this.groupedDcoKey][this.selectedDcoID].placement[0]['backgroundimage1']  = val.backgroundimage1;
        }
        if (val.backgroundimage2 !== '') {
          this.groupedDcos[this.groupedDcoKey][this.selectedDcoID].placement[0]['backgroundimage2']  = val.backgroundimage2;
        }
        if (val.logo !== '') {
          this.groupedDcos[this.groupedDcoKey][this.selectedDcoID].placement[0]['logo1']  = val.logo;
        }
        if (val.offerheadline !== '') {
          this.groupedDcos[this.groupedDcoKey][this.selectedDcoID].placement[0]['offerheadline1']  = val.offerheadline;
        }
        if (val.vehicleimage !== '') {
          this.groupedDcos[this.groupedDcoKey][this.selectedDcoID].placement[0]['vehicleimage1']  = val.vehicleimage;
        }
        if (val.vehiclename !== '') {
          this.groupedDcos[this.groupedDcoKey][this.selectedDcoID].placement[0]['vehiclename1']  = val.vehiclename;
        }
        if (val.ctalabel !== '') {
          this.groupedDcos[this.groupedDcoKey][this.selectedDcoID].placement[0]['ctalabel1']  = val.ctalabel;
          // this.groupedDcos[this.groupedDcoKey][this.selectedDcoID].placement[0]['ctaurl1']  = val.urlvalue;
        }

        if (val.ctaurl !== '') {
          this.groupedDcos[this.groupedDcoKey][this.selectedDcoID].placement[0]['ctaurl1']  = val.ctaurl;
          // this.groupedDcos[this.groupedDcoKey][this.selectedDcoID].placement[0]['ctaurl1']  = val.urlvalue;
        }

        if (val.disclosure !== '') {
          this.groupedDcos[this.groupedDcoKey][this.selectedDcoID].placement[0]['disclosurecopy1']  = val.disclosure;
        }
      }

      if (val.frame === 2) {
        if (val.backgroundimage1 !== '') {
          // const placements: FormArray = <FormArray>this.dcoForm.controls['placement'];
          this.groupedDcos[this.groupedDcoKey][this.selectedDcoID].placement[0]['backgroundimage1']  = val.backgroundimage1;
        }
        if (val.backgroundimage2 !== '') {
          this.groupedDcos[this.groupedDcoKey][this.selectedDcoID].placement[0]['backgroundimage2']  = val.backgroundimage2;
        }
        if (val.logo !== '') {
          this.groupedDcos[this.groupedDcoKey][this.selectedDcoID].placement[0]['logo2']  = val.logo;
        }
        if (val.offerheadline !== '') {
          this.groupedDcos[this.groupedDcoKey][this.selectedDcoID].placement[0]['offerheadline2']  = val.offerheadline;
        }
        if (val.vehicleimage !== '') {
          this.groupedDcos[this.groupedDcoKey][this.selectedDcoID].placement[0]['vehicleimage2']  = val.vehicleimage;
        }
        if (val.vehiclename !== '') {
          this.groupedDcos[this.groupedDcoKey][this.selectedDcoID].placement[0]['vehiclename2']  = val.vehiclename;
        }
        if (val.ctalabel !== '') {
          this.groupedDcos[this.groupedDcoKey][this.selectedDcoID].placement[0]['ctalabel2']  = val.ctalabel;
          // this.groupedDcos[this.groupedDcoKey][this.selectedDcoID].placement[0]['ctaurl1']  = val.urlvalue;
        }
        if (val.ctaurl !== '') {
          this.groupedDcos[this.groupedDcoKey][this.selectedDcoID].placement[0]['ctaurl2']  = val.ctaurl;
          // this.groupedDcos[this.groupedDcoKey][this.selectedDcoID].placement[0]['ctaurl1']  = val.urlvalue;
        }

        if (val.disclosure !== '') {
          this.groupedDcos[this.groupedDcoKey][this.selectedDcoID].placement[0]['disclosurecopy2']  = val.disclosure;
        }
      }

      if (val.frame === 3) {
        if (val.backgroundimage1 !== '') {
          // const placements: FormArray = <FormArray>this.dcoForm.controls['placement'];
          this.groupedDcos[this.groupedDcoKey][this.selectedDcoID].placement[0]['backgroundimage1']  = val.backgroundimage1;
        }
        if (val.backgroundimage2 !== '') {
          this.groupedDcos[this.groupedDcoKey][this.selectedDcoID].placement[0]['backgroundimage2']  = val.backgroundimage2;
        }
        if (val.logo !== '') {
          this.groupedDcos[this.groupedDcoKey][this.selectedDcoID].placement[0]['logo3']  = val.logo;
        }
        if (val.offerheadline !== '') {
          this.groupedDcos[this.groupedDcoKey][this.selectedDcoID].placement[0]['offerheadline3']  = val.offerheadline;
        }
        if (val.vehicleimage !== '') {
          this.groupedDcos[this.groupedDcoKey][this.selectedDcoID].placement[0]['vehicleimage3']  = val.vehicleimage;
        }
        if (val.vehiclename !== '') {
          this.groupedDcos[this.groupedDcoKey][this.selectedDcoID].placement[0]['vehiclename3']  = val.vehiclename;
        }
        if (val.ctalabel !== '') {
          this.groupedDcos[this.groupedDcoKey][this.selectedDcoID].placement[0]['ctalabel3']  = val.ctalabel;
          // this.groupedDcos[this.groupedDcoKey][this.selectedDcoID].placement[0]['ctaurl1']  = val.urlvalue;
        }

        if (val.ctaurl !== '') {
          this.groupedDcos[this.groupedDcoKey][this.selectedDcoID].placement[0]['ctaurl3']  = val.ctaurl;
          // this.groupedDcos[this.groupedDcoKey][this.selectedDcoID].placement[0]['ctaurl1']  = val.urlvalue;
        }

        if (val.disclosure !== '') {
          this.groupedDcos[this.groupedDcoKey][this.selectedDcoID].placement[0]['disclosurecopy3']  = val.disclosure;
        }
      }

      this.dcoForm.get('placement').patchValue(this.groupedDcos[this.groupedDcoKey][this.selectedDcoID].placement);
      this.dcoForm.updateValueAndValidity();
      this.selectedPlacement =  this.groupedDcos[this.groupedDcoKey][this.selectedDcoID].placement[0].placementid;
      this.selectedSize =  this.groupedDcos[this.groupedDcoKey][this.selectedDcoID].placement[0].size;
      console.log(this.dcoForm);
    }, (err) => console.log(err));
  }

  openPopup(type: string, data: FormGroup, update?: boolean) {
    const popupConfig = new MatDialogConfig();
    let actionType = '';
    switch (type) {
      case 'create':
        actionType = 'create';
        popupConfig.disableClose = false;
        popupConfig.autoFocus = true;
        popupConfig.width = '550px';
        popupConfig.data = {
          'type': 'popup',
          'title': `A new version of ${this.selectedPlacement} will be created`,
          'label': 'Click on Continue to Confirm'
        };
        break;
      case 'delete':
        actionType = 'delete';
        popupConfig.disableClose = false;
        popupConfig.autoFocus = true;
        popupConfig.width = '550px';
        popupConfig.data = {
          'type': 'popup',
          'title': `The selected PlacementId will be deleted:  ${this.selectedPlacement}`,
          'label': 'Click on Continue to Confirm'
        };
        break;
      case 'save':
        actionType = 'save';
        popupConfig.disableClose = false;
        popupConfig.autoFocus = true;
        popupConfig.width = '550px';
        popupConfig.data = {
          'type': 'popup',
          'title': `A new version of the Placement ID will be created ${this.selectedPlacement}`,
          'label': 'Click on Continue to Confirm'
        };
        break;
      case 'update':
        actionType = 'update';
        popupConfig.disableClose = false;
        popupConfig.autoFocus = true;
        popupConfig.width = '550px';
        popupConfig.data = {
          'type': 'popup',
          'title': `Placement ID: ${this.selectedPlacement} will be updated`,
          'label': 'Click on Continue to Confirm'
        };
        break;
      case 'no change':
        actionType = 'no change';
        popupConfig.disableClose = false;
        popupConfig.autoFocus = true;
        popupConfig.width = '550px';
        popupConfig.data = {
          'type': 'popup',
          'title': 'Nothing changed, so nothing to save',
          'label': 'Click on Continue to Confirm'
        };
        break;
      default:
        break;
    }
    const popupRef = this.dialog.open(ModalComponent, popupConfig);
    popupRef.afterClosed().subscribe(
      val => {
        if (val !== undefined) {
          /*
              Handles Updated Placement Id
           */
          if (actionType === 'update') {
            this.groupedDcos[this.selectedDco][this.selectedDcoID].updated_date = new Date().toISOString();
            this.utilityService.postByUrl(environment.apiPostDcoUrl, this.groupedDcos[this.selectedDco][this.selectedDcoID])
              .subscribe(() => {
                  this.showSuccess();
                  this.router.navigateByUrl('dcos/success');
                }, (err) => {
                  console.log(err);
                  this.showError();
                }
              );
          }

          /*
             Handles Saving a new Placement
           */

          if (actionType === 'save') {
            const countPids = _.filter(this.filteredDcos, dco => dco.active === true && dco.placement[0].placementid.substr(0, dco.placement[0].placementid.indexOf( '-' )) === this.groupedDcos[this.selectedDco][this.selectedDcoID].placement[0].placementid.substr(0, this.groupedDcos[this.selectedDco][this.selectedDcoID].placement[0].placementid.indexOf( '-' )) && dco.placement[0].size === this.groupedDcos[this.selectedDco][this.selectedDcoID].placement[0].size);
            this.groupedDcos[this.selectedDco][this.selectedDcoID].placement[0].version = countPids.length;
            this.groupedDcos[this.selectedDco][this.selectedDcoID].placement[0].version++;

            this.groupedDcos[this.selectedDco][this.selectedDcoID].placement[0].placementid = this.groupedDcos[this.selectedDco][this.selectedDcoID].placement[0].placementid.substr(0, this.groupedDcos[this.selectedDco][this.selectedDcoID].placement[0].placementid.indexOf( '-' )) + '-' + this.groupedDcos[this.selectedDco][this.selectedDcoID].placement[0].version;
            this.groupedDcos[this.selectedDco][this.selectedDcoID]._id = null;
            this.groupedDcos[this.selectedDco][this.selectedDcoID].updated_date = new Date().toISOString();
            this.utilityService.postByUrl(environment.apiPostDcoUrl, this.groupedDcos[this.selectedDco][this.selectedDcoID])
              .subscribe(() => {
                  this.showSuccess();
                  this.router.navigateByUrl('dcos/success');
                }, (err) => {
                  console.log(err);
                  this.showError();
                }
              );
          }

          /*
            Handles deleteing placement Id
           */

          if (actionType === 'delete') {
            /*
              Store in local, so when page reload user will continue where left off.
             */
            this.localStorage.addToLocalStorage('GroupedDcoKey', this.selectedDco);
            this.localStorage.addToLocalStorage('GroupedDcoID', this.selectedDcoID);

            this.dcoForm.value.active = false;
            this.groupedDcos[this.selectedDco][this.selectedDcoID].active = false;

            this.utilityService.postByUrl(environment.apiPostDcoUrl, this.groupedDcos[this.selectedDco][this.selectedDcoID])
              .subscribe(res => {
                  this.showDeleteSuccess();
                  this.router.navigateByUrl('dcos/success');
                }, (err) => {
                  console.log(err);
                  this.showError();
                }
              );
          }
        }
      }, (err) => {
        console.log(err);
      }
    );
  }

  showSuccess() {
    this.toastrService.success(`Saved successfully for placement ID:  ${this.selectedPlacement}`, 'Success !', {
      positionClass: 'toast-top-center', tapToDismiss: true, closeButton: true
    });
  }

  showDeleteSuccess() {
    this.toastrService.success(`Deleted placement ID:  ${this.selectedPlacement} for ${this.dcoForm.value.make} ${this.dcoForm.value.model} ${this.dcoForm.value.year}`, 'Success !', {
      positionClass: 'toast-top-center', tapToDismiss: true, closeButton: true
    });
  }

  showError() {
    this.toastrService.error(`Failed to save for placement ID: ${this.selectedPlacement}`, 'Failed !', {
      positionClass: 'toast-top-center', tapToDismiss: true, closeButton: true
    });
  }
}



