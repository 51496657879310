import { Injectable } from '@angular/core';

export interface ILocalStorageService {
  createUserAndStoreInLocal: (userData: any) => void;
  getItem: (name: string) => string;
  removeItem: (name: string) => void;
  setItem: (name: string, value: string) => void;
}

@Injectable({
  providedIn: 'root'
})
export class LocalstorageService implements ILocalStorageService {

  constructor() { }

  get getStoredUser(): any {
    return {
      username: localStorage.getItem('username'),
      pacode: localStorage.getItem('pacode'),
      token: localStorage.getItem('token'),
      jwtToken: localStorage.getItem('jwtToken'),
      role: localStorage.getItem('role'),
      passEcode: localStorage.getItem('passEcode')
    };
  }

  public getItem(name: string): string {
    let cookieValue: string = null;
    const cookieName = `${name}`;
    const documentCookies: Array<string> = document.cookie.split(';');
    documentCookies.forEach((cookie) => {
      while (cookie.charAt(0) === '') {
        cookie = cookie.substring(1, cookie.length);
      }
      if (cookie.indexOf(cookieName) === 0) {
        cookieValue = cookie.substring(cookie.length, cookie.length);
      }
    });
 //   console.log('cookie value', cookieValue);
    return cookieValue;
  }

  public removeItem(name: string): void {
    this.setItem(name, '', -1);
  }

  public setItem(name: string, value: string, expireDays: number = 10000): void {
    const date: Date = new Date();
    date.setTime(date.getTime() + expireDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value}; ${expires}`;
  //  console.log('This is when it expires', document.cookie);
  }

  public createUserAndStoreInLocal( userData: any): void {
    localStorage.setItem('username', userData[0].username);
    localStorage.setItem('userDealers', JSON.stringify(userData[0].dealers));
    // localStorage.setItem('pacode', userData[0].dealers[0].pacode);
    // localStorage.setItem('brand', userData[0].dealers[0].brand);
     localStorage.setItem('token',  userData[0].token);
     localStorage.setItem('jwtToken', 'Bearer ' + userData[0].token);
     localStorage.setItem('role', userData[0].role);
     localStorage.setItem('usersFirstName',  userData[0].first_name);
     localStorage.setItem('usersLastName',  userData[0].last_name);
     localStorage.setItem('usersEmail',  userData[0].email);
     localStorage.setItem('passEcode',  userData[0].passEcode);
     localStorage.setItem('userDealersDataComplete',  "false");
  }

  public addToLocalStorage(itemName: string, itemValue: any): void {
    localStorage.setItem(itemName, itemValue);
  }

  public retrieveFromLocalStorage(itemName: string): string {
    return localStorage.getItem(itemName);
  }
}
