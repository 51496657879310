import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

//const BASEURL = 'http://localhost:3003/api/resetpassword';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient) { }

  requestReset(baseUrl, body): Observable<any> {
    return this.http.post(`${baseUrl}/req-reset-password`, body);
  }

  newPassword(baseUrl, body): Observable<any> {
    return this.http.post(`${baseUrl}/new-password`, body);
  }

  ValidPasswordToken(baseUrl, body): Observable<any> {
    return this.http.post(`${baseUrl}/valid-password-token`, body);
  }
  }